import PropTypes from 'prop-types';
import { SharedSection, Title } from './Section.styled';
import { Container } from '@mui/material';

const Section = ({ children, text }) => {
  return (
    <SharedSection>
      <Container maxWidth="xl">
        {text && <Title>{text}</Title>}
        {children}
      </Container>
    </SharedSection>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string,
};

export default Section;
