import Sidebar from 'components/Sidebar/Sidebar';
import Header from 'components/Header/Header';
import Section from 'components/common/Section/Section';
import PageLoader from 'components/common/PageLoader/PageLoader';
import { useSelector } from 'react-redux';
import { selectAuthIsLoading } from 'redux/auth/selectors';
import { nav, navImage, navLocationsPage } from 'configs/nav';
import { useMedia } from 'hooks/useMedia';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { Box } from '@mui/material';
import { Container } from './SharedLayout.styled';

const SharedLayout = () => {
  const isLoading = useSelector(selectAuthIsLoading);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const { isDesktopScreen, isTabletScreen } = useMedia();

  const handleToggleSidebar = () => {
    if (isTabletScreen || isDesktopScreen) {
      return;
    }

    setIsOpenSidebar(prevState => !prevState);
  };

  return (
    <>
      {isLoading ? (
        <PageLoader outShared={true} />
      ) : (
        <Container>
          <Sidebar
            isOpen={isOpenSidebar}
            onCloseSidebar={handleToggleSidebar}
            navList={nav}
          />
          <Box sx={{ flexGrow: 1, position: 'relative' }}>
            <Header
              onOpenSidebar={handleToggleSidebar}
              navList={[...nav, ...navImage, ...navLocationsPage]}
            />
            <main>
              <Section>
                <Suspense fallback={<PageLoader />}>
                  <Outlet />
                </Suspense>
              </Section>
            </main>
          </Box>
        </Container>
      )}
    </>
  );
};

export default SharedLayout;
