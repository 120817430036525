import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'hooks/useMedia';
import { HeaderStyled, MenuBurger, HeaderTitle } from './Header.styled';
import { Box, Container } from '@mui/material';

const Header = ({ onOpenSidebar, navList }) => {
  const location = useLocation();
  const { isMobileScreen } = useMedia();

  const routeConfig = navList.find(({ route }) => route === location.pathname);

  return (
    <HeaderStyled>
      <Container maxWidth="xl">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {isMobileScreen && (
            <MenuBurger type="button" onClick={onOpenSidebar}>
              <MenuIcon />
            </MenuBurger>
          )}
          <HeaderTitle>{routeConfig.title}</HeaderTitle>
        </Box>
      </Container>
    </HeaderStyled>
  );
};

Header.propTypes = {
  onOpenSidebar: PropTypes.func.isRequired,
  navList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Header;
