import { createSlice } from '@reduxjs/toolkit';
import { getAnalytics } from './operation';
import { handlePending, handleFulfilled, handleRejected } from './helpers';

const initialState = {
  visitors: 0,
  orders: 0,
  isLoading: false,
  error: null,
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  extraReducers: builder =>
    builder
      .addCase(getAnalytics.pending, handlePending)
      .addCase(getAnalytics.fulfilled, handleFulfilled)
      .addCase(getAnalytics.rejected, handleRejected),
});

export const analyticsReducer = analyticsSlice.reducer;
