import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

axios.defaults.baseURL = 'https://igs-api.onrender.com';

export const getAllLocations = createAsyncThunk(
  'locations/getAllLocations',
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get('/api/locations');
      return data.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addLocation = createAsyncThunk(
  'locations/addLocation',
  async (locations, thunkAPI) => {
    try {
      const { data } = await axios.post('/api/locations', locations);
      return data.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updatePositionLocations = createAsyncThunk(
  'locations/updatePositionLocations',
  async (locations, thunkAPI) => {
    try {
      await axios.post('/api/locations/position', locations);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteLocation = createAsyncThunk(
  'locations/deleteLocation',
  async (locationId, thunkAPI) => {
    try {
      const { data } = await axios.delete(`/api/locations/${locationId}`);
      return data.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
