import { Routes, Route, Navigate } from 'react-router-dom';
import { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'hooks/useAuth';
import { selectAuthError } from 'redux/auth/selectors';
import { refreshUser } from 'redux/auth/operation';
import { errorToast } from 'helpers/errorToast';
import { ThemeProvider } from '@emotion/react';
import { theme } from 'styles/theme';
import { Toaster } from 'react-hot-toast';
import PageLoader from 'components/common/PageLoader/PageLoader';
import SharedLayout from 'components/SharedLayout/SharedLayout';
import LoginPage from 'pages/LoginPage/LoginPage';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import RestrictedRoute from 'components/RestrictedRoute/RestrictedRoute';

const Home = lazy(() => import('pages/Home/Home'));
const Images = lazy(() => import('pages/Images/Images'));
const AddImageForm = lazy(() => import('components/AddImageForm/AddImageForm'));
const PaginatedImages = lazy(() =>
  import('components/PaginatedImages/PaginatedImages')
);
const Locations = lazy(() => import('pages/Locations/Locations'));
const AddLocationForm = lazy(() =>
  import('components/AddLocationForm/AddLocationForm')
);
const AllLocations = lazy(() => import('components/AllLocations/AllLocations'));

const App = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectAuthError);
  const { isRefreshing, isLoggedIn } = useAuth();

  useEffect(() => {
    dispatch(refreshUser());
  }, [dispatch]);

  if (isLoggedIn) {
    error && errorToast('Something went wrong');
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        {isRefreshing ? (
          <PageLoader outShared={true} />
        ) : (
          <Routes>
            <Route
              path="login"
              element={<RestrictedRoute component={LoginPage} />}
            />
            <Route
              path="/"
              element={
                <PrivateRoute component={SharedLayout} redirectTo="/login" />
              }
            >
              <Route
                index
                element={<PrivateRoute component={Home} redirectTo="/login" />}
              />
              <Route
                path="images"
                element={
                  <PrivateRoute component={Images} redirectTo="/login" />
                }
              >
                <Route index element={<AddImageForm />} />
                <Route
                  path="list"
                  element={<PaginatedImages itemsPerPage={6} />}
                />
              </Route>
              <Route
                path="locations"
                element={
                  <PrivateRoute component={Locations} redirectTo="/login" />
                }
              >
                <Route index element={<AddLocationForm />} />
                <Route path="list" element={<AllLocations />} />
              </Route>

              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </Routes>
        )}
      </ThemeProvider>
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};

export default App;
