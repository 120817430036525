const regExp = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
};

export const validationInputEmail = value => {
  if (value === '') return false;

  return !regExp.email.test(value);
};

export const validationInputPassword = value => {
  if (value === '') return false;

  return value.length < 7;
};
