import LocationOnIcon from '@mui/icons-material/LocationOn';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ImageIcon from '@mui/icons-material/Image';

export const nav = [
  {
    id: '1',
    label: 'dashboard',
    title: 'dashboard',
    to: '/',
    route: '/',
    icon: DashboardIcon,
  },
  {
    id: '2',
    label: 'images',
    title: 'images',
    to: '/images',
    route: '/images',
    icon: ImageIcon,
  },
  {
    id: '3',
    label: 'locations',
    title: 'locations',
    to: '/locations',
    route: '/locations',
    icon: LocationOnIcon,
  },
];

export const navImage = [
  {
    id: '11',
    label: 'add image',
    title: 'images',
    to: '/images',
    route: '/images',
  },
  {
    id: '21',
    label: 'all images',
    title: 'images',
    to: 'list',
    route: '/images/list',
  },
];

export const navLocationsPage = [
  {
    id: '13',
    label: 'add location',
    title: 'locations',
    to: '/locations',
    route: '/locations',
  },
  {
    id: '23',
    label: 'all locations',
    title: 'locations',
    to: 'list',
    route: '/locations/list',
  },
];
