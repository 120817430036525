import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { login, logout, refreshUser } from './operation';
import {
  getActions,
  handlePending,
  handleFulfilled,
  handlePendingUserRefresh,
  refreshUserFulfilledReducer,
  handleRejectedUserRefresh,
  loginFulfilledReducer,
  handleRejectedLogin,
  logoutUserFulfilledReducer,
  handleRejectedLogoutUser,
} from './helpers';
import storage from 'redux-persist/lib/storage';

const initialState = {
  user: { name: null, email: null },
  token: null,
  isLoggedIn: false,
  isRefreshing: false,
  isLoading: false,
  error: null,
  loginError: null,
  logoutError: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: builder =>
    builder
      .addCase(login.fulfilled, loginFulfilledReducer)
      .addCase(login.rejected, handleRejectedLogin)
      .addCase(refreshUser.pending, handlePendingUserRefresh)
      .addCase(refreshUser.fulfilled, refreshUserFulfilledReducer)
      .addCase(refreshUser.rejected, handleRejectedUserRefresh)
      .addCase(logout.fulfilled, logoutUserFulfilledReducer)
      .addCase(logout.rejected, handleRejectedLogoutUser)
      .addMatcher(isAnyOf(...getActions('pending')), handlePending)
      .addMatcher(isAnyOf(...getActions('fulfilled')), handleFulfilled),
});

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token'],
};

export const persistedReducerAuth = persistReducer(
  authPersistConfig,
  authSlice.reducer
);
