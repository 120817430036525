import PropTypes from 'prop-types';
import { BackdropStyled } from './Backdrop.styled';

const Backdrop = ({ isOpen, onClose }) => {
  return <BackdropStyled isOpen={isOpen} onClick={onClose} />;
};

Backdrop.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default Backdrop;
