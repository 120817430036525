import { configureStore } from '@reduxjs/toolkit';
import { analyticsReducer } from './analytics/slice';
import { imagesReducer } from './images/slice';
import { locationsReducer } from './locations/slice';
import { persistedReducerAuth } from './auth/slice';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

export const store = configureStore({
  reducer: {
    analytics: analyticsReducer,
    images: imagesReducer,
    locations: locationsReducer,
    auth: persistedReducerAuth,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
