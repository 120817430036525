import styled from '@emotion/styled';

export const SharedSection = styled.section`
  position: relative;
  padding: 40px 0;
`;

export const Title = styled('h1')(({ theme }) => ({
  color: 'black',
  [theme.breakpoints.up('sm')]: {
    color: 'red',
  },
  [theme.breakpoints.up('md')]: {
    color: 'green',
  },
}));
