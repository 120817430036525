import styled from '@emotion/styled';
import { theme } from 'styles/theme';

export const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '30px 20px',
  maxWidth: '480px',
  width: '100%',
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: '6px',
}));

export const Label = styled.label`
  margin-bottom: 30px;
  width: 100%;
`;

export const ErrorMessagePassword = styled.span`
  display: inline-block;
  margin: 3px 0 0 14px;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  color: red;
`;

export const textFieldCustomStyles = {
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.secondary.light,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.secondary.main,
    },
    '& input': {
      color: theme.palette.secondary.light,
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.secondary.light,
  },
  '&:focus-within .MuiInputLabel-root': {
    color: theme.palette.secondary.main,
  },
};

export const formControlCustomStyled = {
  width: '100%',
  '&:hover': {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  '&:focus-within': {
    '& .MuiInputLabel-root': {
      color: theme.palette.secondary.main,
    },
  },
};

export const inputLabelCustonStyles = {
  color: theme.palette.secondary.light,
};

export const outlinedInputCustomStyles = {
  color: theme.palette.secondary.light,
  '& fieldset': {
    borderColor: theme.palette.secondary.light,
  },
  '&:hover fieldset': {
    color: theme.palette.secondary.main,
  },
  svg: {
    fill: theme.palette.secondary.light,
  },
  '&:focus-within svg': {
    fill: theme.palette.secondary.main,
  },
  '&:hover svg': {
    fill: theme.palette.secondary.main,
  },
};
