import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#494949',
      main: '#171616',
      dark: '#393939',
    },
    secondary: {
      light: '#C3C3C3',
      main: '#FFFFFF',
      dark: '#652F8D',
      contrastText: '#AEAEAE',
    },
    error: {
      main: '#EE4037',
    },
    button: {
      ligth: '#393939',
      main: '#652f8d',
      dark: '#393939',
      contrastText: '#fff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1536,
    },
  },
});
