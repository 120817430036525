import { login, logout, refreshUser } from './operation';

const extraActions = [login, logout, refreshUser];

export const getActions = type => extraActions.map(action => action[type]);

export const handlePending = state => {
  state.isLoading = true;
};

export const handleFulfilled = state => {
  state.isLoading = false;
  state.error = null;
  state.loginError = null;
  state.logoutError = null;
};

export const loginFulfilledReducer = (state, action) => {
  state.user = action.payload.user;
  state.token = action.payload.token;
  state.isLoggedIn = true;
};

export const handleRejectedLogin = (state, action) => {
  state.isLoading = false;
  state.loginError = action.payload;
};

export const handlePendingUserRefresh = state => {
  state.isLoading = true;
  state.isRefreshing = true;
};

export const refreshUserFulfilledReducer = (state, action) => {
  state.user = action.payload.user;
  state.isLoggedIn = true;
  state.isRefreshing = false;
};

export const handleRejectedUserRefresh = (state, action) => {
  state.isRefreshing = false;
  state.isLoading = false;
  state.error = action.payload;
};

export const logoutUserFulfilledReducer = (state, action) => {
  state.user = '';
  state.token = null;
  state.isLoggedIn = false;
};

export const handleRejectedLogoutUser = (state, action) => {
  state.isLoading = false;
  state.logoutError = action.payload;
};
