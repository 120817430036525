import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

axios.defaults.baseURL = 'https://igs-api.onrender.com';

export const getAnalytics = createAsyncThunk(
  'analytics/getAnalytics',
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get('/api/analytics');
      return data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
