import 'modern-normalize';
import { injectGlobal } from '@emotion/css';

injectGlobal`
body {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.86;
    letter-spacing: 0.02em;
    background-color: #171616;
    color: #ffffff;
}


img {
    display: block;
    max-width: 100%;
    height: auto;
}

a,
button,
input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,h2,h3,h4,p {
  margin: 0;
}

.pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.pagination__link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 100%;
  height: 100%;
}

.pagination__page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: all 300ms ease;
  cursor: pointer
}

.pagination__page:hover,
.pagination__page:focus {
  background-color: #c3c3c3;
}

.pagination__page.active {
  background-color: #652f8d;
}

.pagination__button {
  cursor: pointer
}

.pagination__button.disabled svg {
  fill: #aeaeae;
}

.slick-dots .slick-active {
  opacity: 0.5;
}

.custom-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  transition: all 300ms ease;

  &.slick-prev,
  &.slick-next {
    display: none;
  }

  &:hover {
    background-color: #8b8b8b;
    color: #fff;
  }

  &::before {
    content: ''
  }
}

@media screen and (min-width: 600px) {
  .custom-arrow {

  &.slick-prev,
  &.slick-next {
    display: flex;
    z-index: 15;
  }

  &.slick-prev {
    left: -45px;
  }

  &.slick-next {
    right: -45px;
  }
  }
}

@media screen and (min-width: 1536px) {
  .custom-arrow {

  &.slick-prev {
    left: -30px;
  }

  &.slick-next {
    right: -30px;
  }
  }  
}
`;
