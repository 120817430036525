import { getAll, addImage, deleteImage } from './operation';

const extraActions = [getAll, addImage, deleteImage];

export const getActions = type => extraActions.map(action => action[type]);

export const handlePending = state => {
  state.isLoading = true;
};

export const getAllFulfilledReducer = (state, action) => {
  state.isLoading = false;
  state.get.error = null;
  state.items = action.payload;
};

export const addImageFulfilledReducer = (state, action) => {
  state.isLoading = false;
  state.post.error = null;
  state.items.push(action.payload);
};

export const deleteImageFulfilledReducer = (state, action) => {
  state.isLoading = false;
  state.delete.error = null;
  const idx = state.items.findIndex(image => image._id === action.payload._id);
  state.items.splice(idx, 1);
};

export const getAllRejected = (state, action) => {
  state.isLoading = false;
  state.get.error = action.payload;
};

export const addImageRejected = (state, action) => {
  state.isLoading = false;
  state.post.error = action.payload;
};

export const deleteImageRejected = (state, action) => {
  state.isLoading = false;
  state.delete.error = action.payload;
};
