import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

axios.defaults.baseURL = 'https://igs-api.onrender.com';

export const getAll = createAsyncThunk(
  'images/getAll',
  async (imagesCategory, thunkAPI) => {
    try {
      const { data } = await axios.get(`/api/images/${imagesCategory}`);
      return data.data.images;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addImage = createAsyncThunk(
  'images/addImage',
  async (image, thunkAPI) => {
    const { formData, imagesCategory } = image;
    try {
      const { data } = await axios.post(
        `/api/images/${imagesCategory}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return data.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteImage = createAsyncThunk(
  'images/deleteImage',
  async (image, thunkAPI) => {
    const { imageId, imagesCategory } = image;
    try {
      const { data } = await axios.delete(
        `/api/images/${imagesCategory}/${imageId}`
      );
      return data.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
