export const MOBILE_SCREEN = 0;
export const MOBILE_TYPE = 'mobile';

export const TABLET_SCREEN = 960;
export const TABLET_TYPE = 'tablet';

export const DESKTOP_SCREEN = 1200;
export const DESKTOP_TYPE = 'desktop';

export const RESOLUTIONS_LIST = [
  {
    type: MOBILE_TYPE,
    size: MOBILE_SCREEN,
  },
  {
    type: TABLET_TYPE,
    size: TABLET_SCREEN,
  },
  {
    type: DESKTOP_TYPE,
    size: DESKTOP_SCREEN,
  },
];
