import styled from '@emotion/styled';

export const HeaderStyled = styled('div')(({ theme }) => ({
  padding: '20px 0',

  [theme.breakpoints.up('md')]: {
    padding: '30px 0',
  },
}));

export const MenuBurger = styled.button`
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const HeaderTitle = styled('h1')(({ theme }) => ({
  fontFamily: "'Roboto', sans-serif",
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal',
  textTransform: 'capitalize',

  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
  },
}));
