import styled from '@emotion/styled';
import { Link, NavLink } from 'react-router-dom';

export const NavBar = styled('div')(({ isOpen, theme }) => ({
  position: 'fixed',
  top: '0',
  left: '0',
  padding: '30px 0',
  width: '80%',
  height: '100vh',
  transform: isOpen ? 'translateX(0)' : 'translateX(-100%)',
  backgroundColor: theme.palette.primary.dark,
  zIndex: '55',
  transition: 'all 300ms ease',
  overflowY: 'scroll',

  [theme.breakpoints.up('sm')]: {
    width: '320px',
  },
  [theme.breakpoints.up('md')]: {
    transform: 'translateX(0)',
    overflow: 'hidden',
  },
}));

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

export const Logo = styled(Link)`
  padding-left: 30px;
  margin-bottom: 30px;
  text-decoration: none;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const ListLinks = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const Item = styled.li`
  width: 100%;
`;

export const LinkStyled = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 10px 30px;
  text-decoration: none;
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.secondary.main};
  transition: all 150ms ease;

  &.active {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
`;
