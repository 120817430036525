import LoadingButton from '@mui/lab/LoadingButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthIsLoading } from 'redux/auth/selectors';
import { login } from 'redux/auth/operation';
import { successToast } from 'helpers/successToast';
import { errorToast } from 'helpers/errorToast';
import {
  validationInputEmail,
  validationInputPassword,
} from 'helpers/loginFormValidation';
import {
  Form,
  Label,
  ErrorMessagePassword,
  textFieldCustomStyles,
  formControlCustomStyled,
  inputLabelCustonStyles,
  outlinedInputCustomStyles,
} from './LoginForm.styled';
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
} from '@mui/material';

const LoginForm = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [password, setPassword] = useState('');
  const [emptyPassword, setEmptyPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const isLoading = useSelector(selectAuthIsLoading);
  const validationEmail = validationInputEmail(email);
  const validationPassword = validationInputPassword(password);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = e => {
    e.preventDefault();
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case 'email':
        setEmail(value);
        setEmptyEmail(false);
        break;
      case 'password':
        setPassword(value);
        setEmptyPassword(false);
        break;
      default:
        return;
    }
  };

  const handleSumbit = e => {
    e.preventDefault();

    if (email.trim() === '') {
      setEmptyEmail(true);
    }
    if (password.trim() === '') {
      setEmptyPassword(true);
    }
    if (email.trim() === '' || password.trim() === '') {
      return;
    }
    if (validationEmail || validationPassword) {
      return;
    }

    const data = {
      email,
      password,
    };

    dispatch(login(data))
      .unwrap()
      .then(() => {
        successToast('Login was successful');
        setEmail('');
        setPassword('');
      })
      .catch(error => {
        if (error) {
          if (error.response.data.code === 401) {
            errorToast(error.response.data.message);
          } else errorToast('Something went wrong');
        }
      });
  };

  return (
    <Form autoComplete="off" onSubmit={handleSumbit}>
      <Typography variant="h6" mb={3} sx={{ textAlign: 'center' }}>
        Welcome to IGS Admin
      </Typography>
      <Label>
        <TextField
          name="email"
          value={email}
          error={validationEmail}
          onChange={handleChange}
          sx={textFieldCustomStyles}
          label="Email"
          variant="outlined"
          color="secondary"
        />
        {validationEmail && (
          <ErrorMessagePassword>Enter valid email</ErrorMessagePassword>
        )}
        {emptyEmail && (
          <ErrorMessagePassword>This field is required</ErrorMessagePassword>
        )}
      </Label>
      <Label>
        <FormControl variant="outlined" sx={formControlCustomStyled}>
          <InputLabel
            htmlFor="outlined-adornment-password"
            sx={inputLabelCustonStyles}
          >
            Password
          </InputLabel>
          <OutlinedInput
            name="password"
            value={password}
            error={validationPassword}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            sx={outlinedInputCustomStyles}
            color="secondary"
          />
          {validationPassword && (
            <ErrorMessagePassword>
              Password should be at least 8 characters long.
            </ErrorMessagePassword>
          )}
          {emptyPassword && (
            <ErrorMessagePassword>This field is required</ErrorMessagePassword>
          )}
        </FormControl>
      </Label>
      <LoadingButton
        type="submit"
        loading={isLoading}
        variant="contained"
        sx={{
          '&.MuiLoadingButton-loading, &.Mui-disabled': {
            bgcolor: 'secondary.light',
          },
        }}
        color="button"
      >
        Log In
      </LoadingButton>
    </Form>
  );
};

export default LoginForm;
