import PropTypes from 'prop-types';
import Backdrop from 'components/common/Backdrop/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthLogoutError, selectUser } from 'redux/auth/selectors';
import { logout } from 'redux/auth/operation';
import { errorToast } from 'helpers/errorToast';
import { useState } from 'react';
import { useMedia } from 'hooks/useMedia';
import {
  NavBar,
  Nav,
  Logo,
  LinkStyled,
  ListLinks,
  Item,
  CloseButton,
} from './Sidebar.styled';
import {
  Box,
  Typography,
  IconButton,
  Menu,
  Avatar,
  MenuItem,
} from '@mui/material';

const Sidebar = ({ isOpen, onCloseSidebar, navList }) => {
  const dispatch = useDispatch();
  const error = useSelector(selectAuthLogoutError);
  const user = useSelector(selectUser);
  const { isMobileScreen } = useMedia();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseBackdrop = e => {
    if (e.currentTarget === e.target) {
      onCloseSidebar();
    }
  };

  const handleLogOut = () => {
    handleCloseUserMenu();
    dispatch(logout());
  };

  error && errorToast('Can not logout');

  return (
    <>
      <NavBar isOpen={isOpen}>
        <Nav>
          <Logo>IGS ADMIN</Logo>

          <ListLinks>
            {navList.map(({ id, label, to, icon: Icon }) => (
              <Item key={id}>
                <LinkStyled to={to} onClick={onCloseSidebar}>
                  <Icon /> {label}
                </LinkStyled>
              </Item>
            ))}
          </ListLinks>

          <Box sx={{ flexGrow: 0, marginTop: 'auto', paddingLeft: '30px' }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt={user && user.name}
                src="/static/images/avatar/2.jpg"
              />
            </IconButton>

            <Menu
              sx={{ mt: '45px', top: '-100px', left: '50px' }}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleLogOut}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Nav>

        {isMobileScreen && (
          <CloseButton type="button" onClick={onCloseSidebar}>
            <CloseIcon />
          </CloseButton>
        )}
      </NavBar>
      <Backdrop isOpen={isOpen} onClose={handleCloseBackdrop} />
    </>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  navList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
    })
  ).isRequired,
};

export default Sidebar;
