import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getAll, addImage, deleteImage } from './operation';
import {
  handlePending,
  getAllFulfilledReducer,
  getAllRejected,
  addImageFulfilledReducer,
  addImageRejected,
  deleteImageFulfilledReducer,
  deleteImageRejected,
  getActions,
} from './helpers';

const initialState = {
  items: [],
  isLoading: false,
  get: {
    error: null,
  },
  post: {
    error: null,
  },
  delete: {
    isLoading: false,
    error: null,
  },
};

const imagesSlice = createSlice({
  name: 'images',
  initialState,
  extraReducers: builder =>
    builder
      .addCase(getAll.fulfilled, getAllFulfilledReducer)
      .addCase(getAll.rejected, getAllRejected)
      .addCase(addImage.fulfilled, addImageFulfilledReducer)
      .addCase(addImage.rejected, addImageRejected)
      .addCase(deleteImage.fulfilled, deleteImageFulfilledReducer)
      .addCase(deleteImage.rejected, deleteImageRejected)
      .addMatcher(isAnyOf(...getActions('pending')), handlePending),
});

export const imagesReducer = imagesSlice.reducer;
