export const handlePending = state => {
  state.isLoading = true;
};

export const handleFulfilled = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.visitors = action.payload.visitors;
  state.orders = action.payload.orders;
};

export const handleRejected = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};
