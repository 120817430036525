import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { PageLoaderContainer } from './PageLoader.styled';

const PageLoader = ({ outShared = false }) => {
  return (
    <PageLoaderContainer outShared={outShared}>
      <ClipLoader color="#fff" size={50} />
    </PageLoaderContainer>
  );
};

PageLoader.propTypes = {
  outShared: PropTypes.bool,
};

export default PageLoader;
