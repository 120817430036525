import styled from '@emotion/styled';

export const PageLoaderContainer = styled('div')(({ theme, outShared }) => ({
  position: 'fixed',
  top: '0',
  left: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
  backgroundColor: '#171616e8',
  zIndex: '50',

  [theme.breakpoints.up('md')]: {
    left: outShared ? '0' : '160px',
  },
}));
