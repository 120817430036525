import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  getAllLocations,
  addLocation,
  deleteLocation,
  updatePositionLocations,
} from './operation';
import {
  getActions,
  handlePending,
  handleIsLoadingFalse,
  getAllLocationsFulfilledReducer,
  addLocationFulfilledReducer,
  updatePositionLocationsFulfilledReducer,
  deleteLocationFulfilledReducer,
  handleRejectedGet,
  handleRejectedPost,
  handleRejectedUpdatePosition,
  handleRejectedDelete,
} from './helpers';

const initialState = {
  items: [],
  isLoading: false,
  get: {
    error: null,
  },
  post: {
    error: null,
  },
  updatePosition: {
    error: null,
  },
  delete: {
    error: null,
  },
};

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  extraReducers: builder =>
    builder
      .addCase(getAllLocations.fulfilled, getAllLocationsFulfilledReducer)
      .addCase(getAllLocations.rejected, handleRejectedGet)
      .addCase(addLocation.fulfilled, addLocationFulfilledReducer)
      .addCase(addLocation.rejected, handleRejectedPost)
      .addCase(
        updatePositionLocations.fulfilled,
        updatePositionLocationsFulfilledReducer
      )
      .addCase(updatePositionLocations.rejected, handleRejectedUpdatePosition)
      .addCase(deleteLocation.fulfilled, deleteLocationFulfilledReducer)
      .addCase(deleteLocation.rejected, handleRejectedDelete)
      .addMatcher(isAnyOf(...getActions('pending')), handlePending)
      .addMatcher(isAnyOf(...getActions('fulfilled')), handleIsLoadingFalse)
      .addMatcher(isAnyOf(...getActions('rejected')), handleIsLoadingFalse),
});

export const locationsReducer = locationsSlice.reducer;
