import {
  getAllLocations,
  addLocation,
  deleteLocation,
  updatePositionLocations,
} from './operation';

const extraActions = [
  getAllLocations,
  addLocation,
  deleteLocation,
  updatePositionLocations,
];

export const getActions = type => extraActions.map(action => action[type]);

export const handlePending = state => {
  state.isLoading = true;
};

export const handleIsLoadingFalse = state => {
  state.isLoading = false;
};

export const getAllLocationsFulfilledReducer = (state, action) => {
  state.get.error = null;
  state.items = action.payload;
};

export const addLocationFulfilledReducer = (state, action) => {
  state.post.error = null;
  state.items.push(action.payload);
};

export const updatePositionLocationsFulfilledReducer = (state, action) => {
  state.updatePosition.error = null;
  // state.items = action.payload;
};

export const deleteLocationFulfilledReducer = (state, action) => {
  state.delete.error = null;
  const idx = state.items.findIndex(
    location => location._id === action.payload._id
  );
  state.items.splice(idx, 1);
};

export const handleRejectedGet = (state, action) => {
  state.get.error = action.payload;
};

export const handleRejectedPost = (state, action) => {
  state.post.error = action.payload;
};

export const handleRejectedUpdatePosition = (state, action) => {
  state.updatePosition.error = action.payload;
};

export const handleRejectedDelete = (state, action) => {
  state.delete.error = action.payload;
};
